<template>
  <div>
    <div class="h-handle-bg">
      <div class="h-handle-button">
        <p class="h-return">
          <el-button type="text" size="small" @click="config.isDetailDisplay = false">返回</el-button>
        </p>
        <el-button type="primary" size="small" @click="addRule" v-right-code="'Ruletoorderdisassembly:Getemptdto'">新增</el-button>
        <el-button type="primary" size="small" v-right-code="'Ruletoorderdisassembly:Getemptdto'" @click="saveRule">保存</el-button>
      </div>
    </div>
    <el-tabs type="border-card" v-model="activeName" @tab-click="tab1Click">
      <el-tab-pane name="tabbasic">
        <span slot="label">基本信息</span>
        <div class="form-list">
          <el-form :model="dataSource" ref="_ruleForm" :rules="projectCheckRule">
            <el-form-item>
              <el-col :span="4" class="form-title"><span style="color: red">*</span>规则名称：</el-col>
              <el-col :span="8">
                <el-form-item prop="RuleName">
                  <el-input type="text" v-model="dataSource.RuleName" maxlength="50"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4" class="form-title">
                <span style="color: red">*</span>货主名称：</el-col>
              <el-col :span="8">
                  <ChooseCustomer v-model="dataSource.CustomerCode" :customerName.sync="dataSource.CustomerName" :onChange="customerChange"></ChooseCustomer>
              </el-col>
              </el-form-item>
            <el-form-item>
               <el-col :span="4" class="form-title"><span style="color: red">*</span><span>状态：</span></el-col>
              <el-col :span="8">
                <el-form-item>
                  <el-radio v-model="dataSource.EnableStatus" label="100">启用</el-radio>
                  <el-radio v-model="dataSource.EnableStatus" label="200">停用</el-radio>
                </el-form-item>
              </el-col>
              <el-col :span="4" class="form-title">
                <span style="color: red">*</span>标准订单类型：</el-col>
              <el-col :span="8">
                <el-form-item prop="LogisticsOrderType">
                 <el-select v-model="dataSource.LogisticsOrderType" filterable placeholder="请选择">
                    <el-option v-for="item in logisticsOrderTypeList" :key="item.Value" :label="item.Label" :value="item.Value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-form-item>
              
            
            
            <el-form-item>
              <el-col :span="4" class="form-title">规则描述：</el-col>
              <el-col :span="20">
                <el-form-item prop="Remark">
                  <el-input type="textarea" maxlength="200" :rows="2" placeholder="请输入内容" v-model="dataSource.Remark">
                  </el-input>
                </el-form-item>
              </el-col>


            </el-form-item>
          </el-form>
        </div>
      </el-tab-pane>
    </el-tabs>

    <el-tabs type="border-card" v-model="activeNameResult">
      <el-tab-pane name="tabResult" label=" ">
        <span>执行结果（选择以下单据可将物流订单拆解生成N张内部物流单据，可多选）</span>
        <br>
         <br>
          <br>
        <el-form>
          
          <el-col span="6">
            <img src="../../../../../assets/img/Transportationselected.jpg" style="height:120px;"
              v-if="dataSource.TransportOrderStatus" @click="dataSource.TransportOrderStatus = false" />
            <img src="../../../../../assets/img/Transportationnoselected.jpg" style="height:120px;"
              v-if="!dataSource.TransportOrderStatus" @click="dataSource.TransportOrderStatus = true" />
          </el-col>
          <el-col span="2">&nbsp;</el-col>
          <el-col span="6">
            <img src="../../../../../assets/img/warehousingentryselected.jpg" style="height:120px;"
              v-if="dataSource.PhurchaseOrderStatus" @click="dataSource.PhurchaseOrderStatus = false" />
            <img src="../../../../../assets/img/warehousingentrynoselected.jpg" style="height:120px;"
              v-if="!dataSource.PhurchaseOrderStatus" @click="dataSource.PhurchaseOrderStatus = true" />
          </el-col>
          <el-col span="2">&nbsp;</el-col>
          <el-col span="6">
            <img src="../../../../../assets/img/outboundorderselected.jpg" style="height:120px;"
              v-if="dataSource.SaleOrderStatus" @click="dataSource.SaleOrderStatus = false" />
            <img src="../../../../../assets/img/outboundordernoselected.jpg" style="height:120px;"
              v-if="!dataSource.SaleOrderStatus" @click="dataSource.SaleOrderStatus = true" />
          </el-col>
         
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
export default {
  data() {
    var _this = this;
    var checkRuleName = function (rule, value, callback) {
      if (!value) return callback(new Error("请输入规则名称"));
      if(_this.Utils.isEmpty(value)) return callback(new Error("请输入规则名称"));
      callback();
    };
    
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      activeName: "tabbasic",
      activeNameConfig: "first",
      multipleSelection: [],
      isDisabled: false,
      projectCheckRule: {
        RuleName: [{ validator: checkRuleName, trigger: "blur" }],
        
      },
      addruledetailOptions: {
        visible: false,
        size: "small",
        width: "40%"
      },
      currentEnterpriseSelectedRow: {},
      //选择仓库
      warehouseConfig: {
        isDetailDisplay: true,
      },
      warehouseOptions: {
        visible: false,
        size: "small",
      },
      currentWarehouseSelectedRow: {},
      queryParam: {
        PageIndex: 1,
        PageSize: 10,
        //params: {
        //    NiceDate: '',
        //}
      },
      logisticsOrderTypeList:[],
      activeNameResult: 'tabResult',
      configdataSource: {
        ColDefs: {},
        Result: [],
        TotalCount: 0,
      },
    };
  },
  props: {
    config: {
      isDetailDisplay: false,
    },
    dataSource: {},
  },
  computed: {},
  watch: {
    dataSource: {
      handler(curVal, oldVal) {
        //this.isDisabled = (this.Utils.emptyGuid == this.dataSource.WarehouseProjectID || this.dataSource.WarehouseProjectStatus == 100);
        this.isDisabled =
          this.Utils.emptyGuid != this.dataSource.WarehouseProjectID;
        //this.ProjectNo = this.dataSource.ProjectNo;
      },
      deep: true,
    },
  },

  mounted() {
    this.Event.$on("clearEditruletoorderdisassemblyForm", () => this.resetForm);
    this.initLogisticsOrderTypeList();
  },

  methods: {
    initLogisticsOrderTypeList: function () {
      var _this = this;
      _this.$ajax.send(
        "omsapi/ruletoorderdisassembly/getlogisticsordertypelist", "get", {}, (data) => {
          if (data.IsSuccess) {
            _this.logisticsOrderTypeList = data.Result;
          }
        }
      );
    },
    
    addRule: function () {
      this.Event.$emit("onAddRule");
    },
    saveRule: function () {
      var _this = this;
      if(this.dataSource.DisplayLogisticsOrderType!=undefined)
     {
       delete this.dataSource.DisplayLogisticsOrderType;
     }
      
      _this.$refs["_ruleForm"].validate((valid) => {
        _this.isPassValidate = valid;
        if (valid) {
          if (_this.dataSource.Detail != null && _this.dataSource.Detail.length > 0) {
            //配置对象验证
            var detail = _this.dataSource.Detail;
            for (var i = 0; i < detail.length; i++) {
              if (detail[i].ParamFieldName == null || detail[i].ParamFieldName.length == 0) {
                _this.Utils.messageBox("中文描述不能为空", "error");
                return;
              }

              if (detail[i].ParamOperator == null || detail[i].ParamOperator.length == 0) {
                _this.Utils.messageBox("操作符不能为空", "error");
                return;
              }
            }
          }

         if(!_this.dataSource.TransportOrderStatus && !_this.dataSource.PhurchaseOrderStatus && !_this.dataSource.SaleOrderStatus) 
          {
              _this.Utils.messageBox("请至少选择一种执行结果", "error");
              return;
          } 

           if(_this.dataSource.CustomerCode==null || _this.dataSource.CustomerCode.length==0)
                        {
                            _this.Utils.messageBox("未选择货主名称", "error");
                            return ;
                        }

          var routeName =
            (_this.dataSource.Id === "0" || _this.dataSource.Id === 0)
              ? "addruletoorderdisassembly"
              : "editruletoorderdisassembly";

          _this.$ajax.send(
            "omsapi/ruletoorderdisassembly/" + routeName,
            "post",
            _this.dataSource,
            (data) => {

              if (data.IsSuccess) {
                _this.Utils.messageBox(data.OperationDesc, "success");
                _this.$parent.syncDataSource();
                _this.Event.$emit("reloadRuleList");
                _this.dataSource.Detail = [];
                _this.$refs["_ruleForm"].resetFields(); //清空表单
                _this.inputValue = "";
                _this.dataSource.TransportOrderStatus=false;
                _this.dataSource.PhurchaseOrderStatus=false;
                _this.dataSource.SaleOrderStatus=false;
              } else {
                _this.Utils.messageBox(data.OperationDesc, "error");
              }
            }
          );
        } else {
          return false;
        }
      });
    },
    tab1Click: function (tab, event) { },
    tab2Click: function (tab, event) { },
    resetForm() {
      !this.isPassValidate && this.$refs["_ruleForm"].resetFields(); //清空表单
      this.dataSource.EnableStatus = "100";
      this.dataSource.TransportOrderStatus = false;
      this.dataSource.PhurchaseOrderStatus = false;
      this.dataSource.SaleOrderStatus = false;
    },
    reloadPageList: function () {
      this.initialize();
    }, customerChange(){
                //this.warehouseConfig.CustomerCode=this.dataSource.CustomerCode
                //this.productConfig.CustomerCode=this.dataSource.CustomerCode
            },

    initialize() {
      this.onPageChange(this.queryParam);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.$emit("onCheckRow", this.multipleSelection);
    },
    onPageChange(param) {
      var _this = this;
      this.queryParam = param;
      this.$ajax.query(
        "omsapi/logisticsProject/findList",
        "post",
        this.queryParam,
        (data) => {
          _this.dataSource = data;
        }
      );
    },
  },
  components: {
    //"Enterprise": resolve => { require(['../../../selector/enterpriseselector.vue'], resolve) },
    //"Warehouse": resolve => { require(['../../../selector/servicewarehouseselector.vue'], resolve) }
  },
};
</script>

<style>
.el-tag+.el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
</style>